body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f1f1f1;
  position: relative;
}
.header {
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
}
.header__title {
  display: flex;
  align-items: flex-end;
  margin: 0 24px 0 26px;
  padding: 6px 0 0 0;
  background: #fff;
}
.header__logo {
  display: flex;
  width: 165px;
  height: 45px;
  fill: #333;
}
.header__label {
  display: inline-block;
  margin-bottom: -1px;
  margin-left: -2px;
  transform: translate(-2px, -2px);
  font-size: 15px;
  color: #333;
}
.header__resort {
  display: inline-block;
  font-size: 18px;
  color: #1e88e5;
  padding-left: 2px;
}
.header__label-sub {
  color: #666;
  font-size: 14px;
  padding-left: 2px;
}
.step-link {
  text-decoration: none;
  color: #1e88e5;
}
.step-link__caption {
  text-decoration: none;
  color: #0000008a;
  font-size: 12px;
}
.parse__error {
  margin-top: 24px;
  color: #f44336;
  font-size: 15px;
}
.login__error {
  color: #f44336;
  margin-top: 12px;
}
main {
  padding: 24px;
  max-width: 600px;
  margin: 0 auto;
}
main.run-report,
main.parse-report {
  max-width: 800px;
}
.schools__school {
  padding: 12px 4px;
}
.parse-report__help-text {
  padding: 0 0 24px 0;
  color: #555;
}
.parse-report__mapping-text {
  padding: 24px 0 0 0;
  color: #555;
}
.parse-report__stay-before-today-text {
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  justify-content: center;
  align-items: center;
}
.parse-report__stay-before-today-text div {
  padding-left: 12px;
}
.parse-report__stay-before-today-icon {
  width: 24px;
  height: 24px;
  color: #ff9800;
}
.run-report__actions,
.parse-report__actions {
  display: flex;
  justify-content: flex-end;
}
.run__help-text {
  text-align: left;
  width: 100%;
  margin-bottom: 18px;
}
.icon-check {
  color: #4caf50;
}
.icon-unknown {
  color: #ff5722;
}

.MuiCardContent-root {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.MuiCardContent-root.card-content--fullwidth {
  padding: 0;
}
.MuiDialogActions-root.mapping-dialog {
  justify-content: flex-start;
  flex-direction: row-reverse;
}
